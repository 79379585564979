import { styled } from '@mui/system'
import BHLink from '../../molecules/link'
import MuiBreadcrumbs from '@mui/material/Breadcrumbs'

const Root = styled(MuiBreadcrumbs)`
	margin-top: 10px;
	margin-bottom: 10px;
	font-size: ${({ theme }) => theme.typography.pxToRem(12)};
`

const LinkCurrent = styled(BHLink)`
	font-size: ${({ theme }) => theme.typography.pxToRem(12)};
`

const LinkPrev = styled(BHLink)`
	font-size: ${({ theme }) => theme.typography.pxToRem(12)};
`

export { LinkCurrent, LinkPrev, Root }
