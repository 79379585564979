import { styled } from '@mui/system'
import Typography from '../../atoms/typography'

const Root = styled('div')``

const Title = styled(Typography)`
	margin-bottom: 30px;
	text-align: center;
	margin-top: 20px;
`

export { Root, Title }
